import React, { useEffect } from 'react'
import { useRouter } from 'next/router'

export default function MainPage() {
  const router = useRouter()

  useEffect(() => {
    router.push('/signin')
  }, [])
  return <></>
}
